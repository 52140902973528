.mainC {
  width: 800px;
  max-width: 100%;
  font-size: 1.15rem;
}

.mainC > div {
  padding: 0.6rem 0;
  text-align: justify;
}

.mainC h2 {
  font-size: 1.6rem;
}

.mainC b {
  color: #c91fe9;
  background-color: rgba(102, 178, 255, 0.15);
  padding: 1px 5px;
  border-radius: 6px;
  font-weight: 400;
}

.mainC i {
  color: #b95a1a;
}

.mainC code {
  color: #c7254e;
  background-color: #f9f2f4;
  border-radius: 2px;
  font-family: Source Code Pro,DejaVu Sans Mono,Ubuntu Mono,Anonymous Pro,Droid Sans Mono,Menlo,Monaco,Consolas,Inconsolata,Courier,monospace,PingFang SC,Microsoft YaHei,sans-serif;
  font-size: 14px;
  margin: 0px 2px;
  padding: 2px 4px;
}